.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-btn {
  font-size: 30px;
  opacity: 0.8;
  cursor: pointer;
}
nav {
  position: fixed;
  transition: all 0.2s ease;
  transform: translateX(350px);
  visibility: hidden;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 230px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
  padding: 20px;
}

nav.active {
  transform: translateX(0);
  visibility: visible;
}

nav li {
  padding: 5px 10px;
}

nav .nav-call-btn-parent {
  margin-top: 1rem;
  display: block;
  text-align: center;
}

nav.active .nav-call-btn-parent .nav-call-icon {
  margin: 0 5px;
}

.nav-link-parent li {
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.nav-link-parent li:not(:last-child):hover {
  color: #303669;
}

@media screen and (min-width: 991px) {
  nav {
    all: unset;
    display: flex;
    align-items: center;
    font-family: "dana";
  }
  nav.active {
    all: unset;
  }
  nav li {
    all: unset;
  }

  nav .nav-call-btn-parent {
    margin: unset;
  }

  nav.active .nav-call-btn-parent .nav-call-icon {
    all: unset;
  }
  .mobile-btn {
    display: none;
  }

  .nav-link-parent {
    display: flex;
    align-items: center;
  }

  .nav-link-parent li {
    margin: 0 15px;
  }

  .nav-call-btn-parent {
    margin-right: 60px !important;
  }

  .nav-call-btn-parent:hover {
    background-color: #44bf8d;
  }
  .nav-call-icon {
    padding: 0 0 0 5px;
  }
}

@media screen and (min-width: 1200px) {
  .nav-link-parent li {
    font-size: 18px;
  }
  .nav-call-btn-parent {
    margin-right: 150px !important;
  }
}

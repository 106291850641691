header {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url(/public/imgs/header-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.header-content {
  margin: auto;
}

.header-content-left {
  margin: auto;
  margin-top: 1.5rem;
}

.header-content-left img {
  width: 100%;
}
.header-content-right {
  margin-top: 1rem;
}
.header-content-right h1 {
  font-family: "pinar" !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #003379;
  line-height: 1.3;
}
.header-content-right p {
  font-size: 18px;
  font-weight: 300;
  color: #212529;
  margin-top: 0.75rem;
}

.header-content-right-btns {
  text-align: center;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.header-content-right-btns button {
  all: unset;
  font-size: 14px;
  border-radius: 50px;
}

.header-btns {
  margin-left: 5px;
}

.header-content-right-btns:nth-child() {
  color: red;
}

@media screen and (min-width: 991px) {
  header {
    height: 300px;
  }

  .header-content {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    margin: 5rem 0;

  }

  .header-content-left,
  .header-content-right {
    width: 50%;
  }

  .header-content-right {
    margin-top: 5rem;
  }
  .header-content-right h1 {
    text-align: right;
  }

  .header-content-right-btns {
    text-align: right;
    justify-content: unset;
  }
}

@media screen and (min-width: 1200px) { 
  header {
    height: 400px;
  }
}
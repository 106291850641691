.about-section {
  margin: 45rem auto 0 auto !important;
  padding: 50px 0;
  background-color: rgba(182, 178, 255, 0.22);
}

.about-section-left {
  display: none;
}

.about-section-left img {
  width: 80%;
}

.about-btn-parent {
  text-align: center;
  margin-top: 1rem;
}

.about-btn-parent button svg {
  margin-left: 5px;
}

@media screen and (min-width: 768px) {
  .about-section {
    margin: 55rem auto 0 auto !important;
  }
}

@media screen and (min-width: 991px) {
  .about-section {
    margin: 23rem auto 0 auto !important;
  }

  .about-section > div {
    display: flex;
    justify-content: space-between;
  }
  .about-section-left,
  .about-section-right {
    width: 50% !important;
  }

  .about-section-left {
    display: block;
  }
  .about-btn-parent {
    text-align: right;
  }
}

@media screen and (min-width: 1200px) {
  .about-section {
    margin: 20rem auto 0 auto !important;
  }
}

@media screen and (min-width: 1600px) {
  .about-section {
    margin: 23rem auto 0 auto !important;
  }
}
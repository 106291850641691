* {
  margin: 0;
  padding: 0;
  font-family: "estedad" !important;
  font-weight: 300;
  direction: rtl;
}

a {
  all: unset;
  cursor: pointer;
}

button,
input,
textarea,
label {
  all: unset;
}

p,
span {
  margin-top: 0.75rem;
  font-variation-settings: "wght" 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "pinar" !important;
  color: #003379;
}

ul,
li {
  list-style: none;
}

h1,
h2,
h3 {
  font-size: 40px;
  line-height: 1.4;
}

span {
  font-size: 18px;
}

body {
  height: 100vh;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.line-through {
  text-decoration: line-through;
}

.container-max-width {
  max-width: 720px !important;
  margin: auto;
}

.page-default-padding {
  padding: 15px 15px !important;
}

.green-btn,
.bordered-btn {
  all: unset;
  cursor: pointer;
  padding: 3px 25px;
  border-radius: 50px;
  transition: all 0.1s;
  font-size: 16px !important;
}

.green-btn {
  color: #fff;
  background-color: #00a65c;
}

.green-btn:hover {
  background-color: #44bf8d;
}

.bordered-btn {
  color: #558b95;
  outline: 2px solid #558b95;
  padding: 1px 25px !important;
}
.bordered-btn:hover {
  color: #003379;
  outline: 2px solid #003379;
}
@media screen and (min-width: 576px) {
  .container-max-width {
    max-width: 540px !important;
  }
}

@media screen and (min-width: 768px) {
  .container-max-width {
    max-width: 720px !important;
  }
}

@media screen and (min-width: 991px) {
  .page-default-padding {
    padding: 15px 25px !important;
  }

  .container-max-width {
    max-width: 960px !important;
  }
}

@media screen and (min-width: 1200px) {
  .container-max-width {
    max-width: 1140px !important;
  }
}

.customers-section {
  margin: auto;
  padding: 2rem 0;
}

.slider-content {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 4rem;
}

.slider-customer-image {
  text-align: center;
}

.slider-customer-image img {
  width: 90%;
  max-width: 550px;
  max-height: 500px;
}

.slider-customer-details > p {
  margin-bottom: 1.5rem;
}

.customer-name {
  color: #003379;
  border-right: 4px solid #003379;
  background-color: rgba(0, 51, 121, 0.15);
  padding: 5px;
  border-radius: 5px 0 0 5px;
}

.customer-icon {
  font-size: 25px;
  color: #003379;
  vertical-align: middle;
  padding: 0 0 0 1px;
}

.swiper-pagination-bullet {
  padding: 1px;
  transition: all 0.3s;
}

.swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 10px;
}

@media screen and (min-width: 991px) {
  .customers-section {
    width: 90%;
  }

  .slider-content {
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-direction: row;
  }

  .slider-customer-details {
    width: 50%;
  }

  .slider-customer-image img {
    width: 110%;
    max-height: 400px;
  }
}

@media screen and (min-width: 1400px) {
  .customers-section {
    width: 70%;
  }

}
